















































@import "src/assets/style/global"
$cloud-height: 60px
.cloud
  overflow: hidden
  .cell
    animation: float 2.8s infinite ease-in-out
    &:nth-child(1)
      animation-delay: .2s
    &:nth-child(2)
      animation-delay: .5s
.container
  & svg
    width: 100%
    min-width: 1000px
    height: auto
    transform: translateY(20px)
    @media screen and (max-width: 780px)
      width: 200vw
      transform: translate(-50%, 32px)
      width: 100vw
.footer
  width: 100%
  word-break: break-all
  height: $cloud-height + 10px
  padding-top: $cloud-height - 40px
  box-sizing: border-box
  background-color: white
  color: #555
  @include flex-center
  flex-direction: column
  a
    text-decoration: none
    font-size: .9rem
    @include default-a
@keyframes float
  0%
    transform: translateY(0)
  50%
    transform: translateY(18px)
  100%
    transform: translateY(0)
